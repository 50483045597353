// DS V2
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import analytics from '@analytics';
import { Button, TextInput, Typography } from '@ds';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import { GlobeIcon, SearchIcon } from '@heroicons/react/outline';
import { UserAddIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useAsyncDebounce } from 'react-table';
import { useInvestorSearchContactsAndShareholdingsAutocompleteQuery } from '@/apollo/generated';
import AutocompleteContacts from '@/components/investors/search/autocomplete/contacts';
import AutocompleteLoading from '@/components/investors/search/autocomplete/loading';
import AutocompleteShareholdings from '@/components/investors/search/autocomplete/shareholdings';
import CreateContactModal from '@/components/investors/search/global/contacts/create-contact-modal';
import { useInvestorsSearchGlobal } from '@/components/investors/search/global/context';
import useBreakpoint from '@/components/pdf-viewer/use-breakpoint';
import TableEmpty from '@/components/utils/tables/empty';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';
import { FLAGS } from '@/hooks/use-feature-toggles';

interface Props {
  fullWidth?: boolean;
}

const Autocomplete: React.FC<Props> = ({ fullWidth }) => {
  const router = useRouter();
  const { currentCompanyProfileUser, isPremium } =
    useCurrentCompanyProfileUser();
  const { pathname, query } = router;
  const { marketListingKey, search, tab } = query;
  const [searchPhrase, setSearchPhrase] = useState((search as string) ?? '');
  const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState('');
  const [showCreateContactModal, setShowCreateContactModal] = useState(false);
  const { investorSearchPush, totals } = useInvestorsSearchGlobal();
  const breakPoint = useBreakpoint();
  const isMobile = breakPoint ? ['sm', 'md'].includes(breakPoint) : false;

  const [open, setOpen] = useState(false);

  const [searchBarCoordinates, setSearchBarCoordinates] = useState({
    left: 0,
    top: 0,
    width: 0,
  });

  const onSearchChange = useAsyncDebounce((value) => {
    setDebouncedSearchPhrase(value);
  }, 150);

  const searchBarElement = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (search && search.length > 0) setSearchPhrase(search as string);
  }, [search]);

  useEffect(() => {
    if (!open && debouncedSearchPhrase.length > 1) setOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchPhrase]);

  useEffect(() => {
    // Reset search when search form cleared
    if (!open && search?.length && !debouncedSearchPhrase && !searchPhrase)
      investorSearchPush({ search: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchPhrase, open]);

  useEffect(() => {
    function setCoordinates() {
      if (searchBarElement.current) {
        setSearchBarCoordinates({
          left: searchBarElement.current.offsetLeft,
          top: searchBarElement.current.offsetTop,
          width: searchBarElement.current.offsetWidth,
        });
      }
    }

    setCoordinates();
    window.addEventListener('resize', setCoordinates);
    return () => {
      window.removeEventListener('resize', setCoordinates);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, searchBarElement]);

  const contactSearchKey =
    currentCompanyProfileUser.profile.featuresEnabled.includes(
      FLAGS.sunriceGrowerNumber
    )
      ? 'search_with_sunrice_grower_number'
      : 'search_with_investor_users';

  const { data, loading, networkStatus } =
    useInvestorSearchContactsAndShareholdingsAutocompleteQuery({
      fetchPolicy: 'no-cache',
      skip: !debouncedSearchPhrase.length,
      variables: {
        contactsFirst: 3,
        contactsOptions: {
          filters: [
            {
              key: contactSearchKey,
              value: debouncedSearchPhrase,
            },
          ],
        },
        shareholdingsFirst: 3,
        shareholdingsOptions: {
          filters: [
            {
              key: 'search',
              value: debouncedSearchPhrase,
            },
          ],
          orders: [
            {
              key: 'share_count',
              value: 'desc',
            },
          ],
        },
      },
    });

  const openCreateContactModal = () => {
    // Close the search dialog
    setOpen(false);
    // Open the create contact modal
    setShowCreateContactModal(true);
  };

  const pushSearchChange = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(false);
    // Clear pagination / sorting when search is applied
    const newQuery = {
      marketListingKey: marketListingKey,
      search: debouncedSearchPhrase,
    };
    router.replace(
      {
        pathname,
        query: tab ? { ...newQuery, tab: tab } : newQuery,
      },
      undefined,
      {
        shallow: true,
      }
    );
  };

  const renderContent = useCallback(() => {
    if (loading) {
      return Array.from({ length: 8 }, (v, index) => (
        <AutocompleteLoading key={`${v}-${index}`} />
      ));
    }

    if (!debouncedSearchPhrase.length) {
      return (
        <div className="border-t border-gray-100 px-6 py-14 text-center sm:px-14">
          <GlobeIcon
            aria-hidden="true"
            className="mx-auto h-6 w-6 text-gray-400"
          />
          <p className="mt-4 font-semibold text-gray-900">
            Search for contacts or shareholders
          </p>
          <p className="mt-2 text-gray-500">
            Quickly access contacts and shareholders by running a global search.
          </p>
        </div>
      );
    }

    if (data?.contacts?.edges?.length || data?.shareholdings?.edges?.length) {
      return (
        <Combobox.Options
          static
          className="max-h-screen scroll-pt-11 overflow-y-auto md:max-h-[712px]"
        >
          <Combobox.Option
            className={({ active }) =>
              clsx(
                'cursor-pointer select-none transition-colors',
                active ? 'bg-amplify-green-50' : 'bg-white'
              )
            }
            value={'Create new contact'}
            onClick={() => {
              openCreateContactModal();
              analytics.track(
                'create_contact_from_homepage_search_bar_clicked'
              );
            }}
          >
            <div
              className={clsx(
                'text-amplify-green-700 flex h-[54px] items-center justify-start px-6',
                isMobile ? 'border-b' : ''
              )}
            >
              <UserAddIcon className="mr-2 h-5 w-5" />
              <Typography variant="text-label-sm">
                Create new contact - &quot;{debouncedSearchPhrase}&quot;
              </Typography>
            </div>
          </Combobox.Option>

          {data.contacts ? (
            <AutocompleteContacts
              contacts={data.contacts}
              debouncedSearchPhrase={debouncedSearchPhrase}
              loading={[1, 2, 3, 4].includes(networkStatus)}
              setOpen={setOpen}
            />
          ) : null}
          {isPremium && data.shareholdings ? (
            <AutocompleteShareholdings
              debouncedSearchPhrase={debouncedSearchPhrase}
              loading={[1, 2, 3, 4].includes(networkStatus)}
              setOpen={setOpen}
              shareholdings={data.shareholdings}
            />
          ) : null}
        </Combobox.Options>
      );
    }

    return (
      <table className="w-full">
        <tbody>
          <TableEmpty
            action={
              <Button
                className="mt-4"
                size="sm"
                variant="primary"
                onClick={openCreateContactModal}
              >
                Create new contact
              </Button>
            }
            message="No investors found"
            secondaryMessage={`Your search or filter did not match any results.
            Please try again or create a new contact.`}
          />
        </tbody>
      </table>
    );
  }, [
    data,
    debouncedSearchPhrase,
    isMobile,
    isPremium,
    loading,
    networkStatus,
  ]);

  const widthToUse = fullWidth ? 'w-full' : 'max-w-[700px]';
  const searchPlaceholder =
    currentCompanyProfileUser.profile.featuresEnabled.includes(
      FLAGS.sunriceGrowerNumber
    )
      ? 'Search investor by name, username, email or grower number'
      : 'Search investor by name, username, email';
  return (
    <div
      ref={searchBarElement}
      className={`relative ${widthToUse} px-4 pb-2 md:px-0`}
    >
      <form
        className="relative flex w-full flex-col gap-2"
        onSubmit={pushSearchChange}
      >
        <TextInput
          autoFocus
          LeadingIcon={SearchIcon}
          aria-placeholder={searchPlaceholder}
          className="w-full rounded-lg"
          placeholder={searchPlaceholder}
          type="search"
          value={searchPhrase}
          onChange={(e) => {
            const searchPhrase = e.target.value;
            setSearchPhrase(searchPhrase);
            onSearchChange(searchPhrase);
          }}
        />
        {search ? (
          <Typography className="text-gray-500" variant="text-body-md">
            {`${totals.all} results found for "${search}"`}
          </Typography>
        ) : null}
      </form>

      <Transition.Root as={Fragment} show={open}>
        <Dialog as="div" className="absolute z-50" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-all md:bg-black/60 md:backdrop-blur-[2px]" />
          </Transition.Child>

          <div
            className="fixed inset-0 z-10 overflow-y-auto"
            style={
              !isMobile
                ? {
                    left: searchBarCoordinates.left,
                    top: searchBarCoordinates.top,
                    width: searchBarCoordinates.width,
                  }
                : {
                    bottom: 0,
                    left: 0,
                    right: 0,
                    top: searchBarCoordinates.top,
                  }
            }
          >
            <Transition.Child
              as={'div'}
              className="h-full md:h-auto"
              enter="ease-out duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel
                className={`mb-4 ${widthToUse} transition-all md:mb-1`}
              >
                <form
                  className="relative w-full px-4 md:px-0"
                  onSubmit={pushSearchChange}
                >
                  <div className="w-full">
                    <TextInput
                      LeadingIcon={SearchIcon}
                      aria-placeholder="Enter name, investor hub username, email or CHESS information"
                      className="w-full"
                      placeholder="Enter name, investor hub username, email or CHESS information"
                      type="search"
                      value={searchPhrase}
                      onChange={(e) => {
                        const searchPhrase = e.target.value;
                        setSearchPhrase(searchPhrase);
                        onSearchChange(searchPhrase);
                      }}
                    />
                  </div>
                </form>
              </Dialog.Panel>

              <Dialog.Panel
                className={`${widthToUse} overflow-hidden border-y bg-white transition-all md:rounded-lg md:border-x md:shadow`}
              >
                {/* FIXME: onchange should work, think that will allow you to downpress to go through results */}
                <Combobox value={debouncedSearchPhrase} onChange={() => false}>
                  {renderContent()}
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <CreateContactModal
        closeModal={() => setShowCreateContactModal(false)}
        open={showCreateContactModal}
        placeholderValue={debouncedSearchPhrase}
      />
    </div>
  );
};

export default Autocomplete;
