// DS V2
import { Dispatch, SetStateAction } from 'react';
import { Typography } from '@ds';
import { Combobox } from '@headlessui/react';
import { ChevronRightIcon, SearchIcon } from '@heroicons/react/outline';
import { MailIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { InvestorSearchContactsAndShareholdingsAutocompleteQuery } from '@/apollo/generated';
import AutocompleteLoading from '@/components/investors/search/autocomplete/loading';
import { useInvestorsSearchGlobal } from '@/components/investors/search/global/context';
import Tags from '@/components/investors/search/global/tags';
import useBreakpoint from '@/components/pdf-viewer/use-breakpoint';
import TableEmpty from '@/components/utils/tables/empty';
import routes from '@/utils/routes';

interface Props {
  contacts: NonNullable<
    InvestorSearchContactsAndShareholdingsAutocompleteQuery['contacts']
  >;
  debouncedSearchPhrase: string;
  loading: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const AutocompleteContacts: React.FC<Props> = ({
  contacts,
  debouncedSearchPhrase,
  loading,
  setOpen,
}) => {
  const breakPoint = useBreakpoint();
  const isMobile = breakPoint ? ['sm', 'md'].includes(breakPoint) : false;
  const router = useRouter();
  const {
    query: { marketListingKey },
  } = router;
  const { investorSearchPush } = useInvestorsSearchGlobal();

  return (
    <>
      <div className="flex min-h-[52px] items-center gap-1 bg-gray-200 px-6 text-gray-700">
        <Typography variant="text-label-md">Contacts</Typography>
        <div className="rounded-full bg-gray-300 px-2 py-0.5">
          <Typography variant="text-body-sm">
            {`${contacts.total || 0}`}
          </Typography>
        </div>
      </div>
      {loading ? (
        <>
          {Array.from({ length: 3 }, (v, index) => (
            <AutocompleteLoading key={`${v}-${index}`} />
          ))}
        </>
      ) : null}
      {contacts.edges && contacts.edges.length ? (
        <ul>
          {contacts.edges.map((edge) => {
            if (!edge?.node) return null;
            const { node: contact } = edge;
            const { email, firstName, id, investor, lastName, tags } = contact;
            return (
              <Combobox.Option
                key={id}
                className={({ active }) =>
                  clsx(
                    'cursor-pointer select-none transition-colors',
                    active ? 'bg-gray-50' : 'bg-white'
                  )
                }
                value={contact}
                onClick={() =>
                  router.push(
                    routes.investors.search.contacts.contact.href(
                      marketListingKey as string,
                      contact.id
                    )
                  )
                }
              >
                {isMobile ? (
                  <div className="flex justify-between border-t p-4">
                    <div className="flex-1">
                      <div className="space-y-0.5">
                        <div className="mb-4 flex flex-col items-start gap-4">
                          <div>
                            <Typography variant="text-label-sm">
                              {firstName || lastName
                                ? `${firstName || ''} ${lastName || ''}`
                                : '-'}
                            </Typography>
                            <div className="mt-1 flex items-center gap-1 text-gray-500">
                              <MailIcon className="h-4 w-4 shrink-0" />
                              <Typography>{email || '-'}</Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-cols-3 gap-3">
                        <Typography
                          className="text-gray-500"
                          variant="text-button-sm"
                        >
                          Username
                        </Typography>
                        <div className="col-span-2">
                          <Typography
                            className="text-gray-500"
                            variant="text-body-sm"
                          >
                            {investor ? investor.username : '-'}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div className="flex w-8 grow-0 items-center justify-center text-primary-green-dark">
                      <ChevronRightIcon className="h-6 w-6" />
                    </div>
                  </div>
                ) : (
                  <div className="flex min-h-[84px] items-center justify-between border-b px-6">
                    <div className="w-[44%] flex-none">
                      <Typography
                        className="truncate text-gray-900"
                        variant="text-label-sm"
                      >
                        {firstName || lastName
                          ? `${firstName || ''} ${lastName || ''}`
                          : '-'}
                      </Typography>
                      <Typography
                        className="truncate text-gray-500"
                        variant="text-body-sm"
                      >
                        {email || '-'}
                      </Typography>
                    </div>
                    <div className="w-[18%] flex-none">
                      <Typography
                        className="truncate text-gray-500"
                        variant="text-body-sm"
                      >
                        {investor?.username}
                      </Typography>
                    </div>
                    <div className="w-[30%] flex-none">
                      {tags ? <Tags tags={tags} /> : null}
                    </div>
                  </div>
                )}
              </Combobox.Option>
            );
          })}
          <Combobox.Option
            className={({ active }) =>
              clsx(
                'cursor-pointer select-none transition-colors',
                active ? 'bg-amplify-green-50' : 'bg-white'
              )
            }
            value={'View all contacts'}
            onClick={() => {
              setOpen(false);

              if (router.pathname != '/[marketListingKey]/investors/search') {
                return router.push({
                  pathname: routes.investors.search.href(
                    router.query.marketListingKey as string
                  ),
                  query: { search: debouncedSearchPhrase },
                });
              } else {
                investorSearchPush({ search: debouncedSearchPhrase });
              }
            }}
          >
            <div
              className={clsx(
                'text-amplify-green-700 flex h-[54px] items-center justify-start px-6',
                isMobile ? 'border-y' : ''
              )}
            >
              <SearchIcon className="mr-2 h-5 w-5" />
              <Typography variant="text-label-sm">
                See all contact results
              </Typography>
            </div>
          </Combobox.Option>
        </ul>
      ) : (
        <table className="w-full">
          <tbody>
            <TableEmpty message="No investors found in contacts" />
          </tbody>
        </table>
      )}
    </>
  );
};

export default AutocompleteContacts;
