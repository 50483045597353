// DS V2
import { useEffect, useState } from 'react';
import analytics from '@analytics';
import {
  Button,
  TextInput,
  Typography,
  AddressAutocomplete,
  PhoneInput,
} from '@ds';
import { MinusIcon, PlusIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import Router, { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useCreateContactMutation } from '@/apollo/generated';
import GenericModal from '@/components/modals/generic-modal';
import { useAlert } from '@/contexts/alert-context';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';
import { FLAGS } from '@/hooks/use-feature-toggles';
import { allCountries, Country } from '@/utils/countries';
import routes from '@/utils/routes';

interface FormData {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  sunriceGrowerNumber?: string;
}

interface Props {
  closeModal: () => void;
  open: boolean;
  placeholderValue?: string;
}

type registerKey = keyof FormData;

interface AdditionalFieldsExpanderProps {
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AdditionalFieldsExpander: React.FC<
  AdditionalFieldsExpanderProps
> = ({ isExpanded, setIsExpanded }) => (
  <div
    className="border-fresh-neon-25 bg-fresh-neon-25 shadow-xs col-span-4 my-2 flex cursor-pointer flex-row rounded-lg px-4 py-2 transition-all"
    onClick={() => setIsExpanded(!isExpanded)}
  >
    <Typography
      className="text-fresh-neon-900 my-auto"
      variant="text-button-md"
    >
      {isExpanded ? 'Hide' : 'Show'} additional fields
    </Typography>
    <span className="grow" />
    {isExpanded ? (
      <MinusIcon className="text-fresh-neon-900 my-auto h-5 transition-all" />
    ) : (
      <PlusIcon className="text-fresh-neon-900 my-auto h-5 transition-all" />
    )}
  </div>
);

const CreateContactModal: React.FC<Props> = ({
  closeModal,
  open,
  placeholderValue,
}) => {
  const { formatAndShowError, showAlert } = useAlert();

  const { currentCompanyProfileUser, isUK } = useCurrentCompanyProfileUser();

  const [createContact, { loading }] = useCreateContactMutation();

  const [address, setAddress] = useState<AmplifyAddress>({
    components: {
      addressCity: '',
      addressCountry: '',
      addressLineOne: '',
      addressLineTwo: '',
      addressPostcode: '',
      addressState: '',
    },
    formattedAddress: '',
  });

  const [number, setNumber] = useState<string>('');
  const [selectedCountry, setSelectedCountry] = useState<Country>(
    // defaults to UK or AU
    isUK ? allCountries[234] : allCountries[13]
  );

  const { formState, handleSubmit, register, setValue } = useForm<FormData>({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: number,
    },
    resolver: yupResolver(
      yup
        .object()
        .shape({
          email: yup.string().trim().lowercase().email('Email is invalid.'),
          firstName: yup.string(),
          lastName: yup.string(),
          phoneNumber: yup
            .string()
            .trim()
            .test('isPhoneValid', 'Contact number is invalid.', (value) => {
              if (!value) {
                return true;
              }

              try {
                const result = parsePhoneNumber(
                  value,
                  selectedCountry.abbr as CountryCode
                );

                return result.isValid();
              } catch (error) {
                return false;
              }
            }),
        })
        .test(
          'at-least-one',
          'At least one field must be populated.',
          (value) => {
            // Check if any field has a non-empty value (after trimming)
            const hasEmail = !!value.email?.trim();
            const hasFirstName = !!value.firstName?.trim();
            const hasLastName = !!value.lastName?.trim();
            const hasPhone = !!value.phoneNumber?.trim();
            const hasAddress = !!address?.formattedAddress?.trim();

            return (
              hasEmail || hasFirstName || hasLastName || hasPhone || hasAddress
            );
          }
        )
    ),
  });

  const { query } = useRouter();
  const ticker = query.marketListingKey as string;

  useEffect(() => {
    if (placeholderValue) {
      if (placeholderValue.includes('@')) {
        setValue('email', placeholderValue);
        setValue('firstName', '');
      } else {
        setValue('email', '');
        setValue('firstName', placeholderValue);
      }
    }
  }, [setValue, placeholderValue]);

  useEffect(() => {
    if (
      !formState.isValid &&
      !formState.isValidating &&
      formState.isSubmitting
    ) {
      formatAndShowError('Please fill one of the fields to create a contact.');
    }
  }, [
    formState.isValid,
    formState.isValidating,
    formState.isSubmitting,
    formatAndShowError,
  ]);

  const onSubmit = handleSubmit(async (data) => {
    const trimmedMobileNumber =
      data.phoneNumber.trim() &&
      parsePhoneNumber(data.phoneNumber, selectedCountry.abbr as CountryCode)
        .number;

    const formWithAddress = { ...data, ...address.components };

    const variables = {
      contact: {
        ...formWithAddress,
        phoneNumber: trimmedMobileNumber,
      },
    };

    createContact({ variables })
      .then(async (response) => {
        // Contact created, redirect to the new contact
        analytics.track('irm_contact_created');
        if (response.data?.createContact.id) {
          showAlert({
            description: `Contact created.`,
            variant: 'success',
          });

          await Router.push(
            routes.investors.search.contacts.contact.href(
              ticker,
              response.data.createContact.id
            )
          );
        } else {
          throw new Error('Unable to create contact. Please try again.');
        }
      })
      .catch(formatAndShowError);
  });

  const renderInput = (key: registerKey, title: string, className?: string) => {
    return (
      <div className={className || 'col-span-4'}>
        <div className="mb-1.5 flex items-center justify-between">
          <Typography className="text-gray-700" variant="text-label-sm">
            {title}
          </Typography>
        </div>
        {key === 'phoneNumber' ? (
          <PhoneInput
            number={number}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            value="0"
            {...register(key)}
            error={!!formState.errors[key]?.message}
            helperText={formState.errors[key]?.message}
            id={key}
            onChange={(newValue: string) => setNumber(newValue)}
          />
        ) : (
          <TextInput
            {...register(key)}
            error={!!formState.errors[key]?.message}
            helperText={formState.errors[key]?.message}
            id={key}
          />
        )}
      </div>
    );
  };

  return (
    <GenericModal open={open} onClose={closeModal}>
      <form className="p-8">
        {/* Header */}
        <Typography className="mb-6 text-gray-900" variant="text-display-sm">
          Create contact
        </Typography>

        {/* Form */}
        <div className="mb-8 grid w-full grid-cols-4 gap-5">
          {/* Email / Firstname / Lastname / Contact number */}
          {renderInput('email', 'Email', 'col-span-4')}
          {renderInput('firstName', 'First Name', 'col-span-4 md:col-span-2')}
          {renderInput('lastName', 'Last Name', 'col-span-4 md:col-span-2')}

          <>
            {renderInput('phoneNumber', 'Contact number')}
            {/* Address1 */}
            <div className="col-span-full">
              <div className="mb-0.5">
                <Typography className="text-gray-700" variant="text-label-sm">
                  Address
                </Typography>
              </div>
              <AddressAutocomplete
                address={address}
                placeholder="Begin by typing the address"
                setAddress={setAddress}
              />
              <Typography className="mt-1 text-gray-600" variant="text-body-sm">
                Type in format: unit / floor number, street address, city,
                state, country
              </Typography>
            </div>

            {currentCompanyProfileUser.profile.featuresEnabled.includes(
              FLAGS.sunriceGrowerNumber
            ) && (
              <div className="col-span-full">
                <div className="mb-1.5 flex items-center justify-between">
                  <Typography className="text-gray-700" variant="text-label-sm">
                    Grower number
                  </Typography>
                </div>
                <TextInput
                  {...register('sunriceGrowerNumber')}
                  error={!!formState.errors.sunriceGrowerNumber?.message}
                  helperText={formState.errors.sunriceGrowerNumber?.message}
                  id="sunriceGrowerNumber"
                />
              </div>
            )}
          </>
        </div>

        <div className="flex w-full items-center gap-5">
          <Button
            className="w-full"
            variant="secondary-gray"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button className="w-full" disabled={loading} onClick={onSubmit}>
            Create contact
          </Button>
        </div>
      </form>
    </GenericModal>
  );
};

export default CreateContactModal;
