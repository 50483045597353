import { Skeleton } from '@ds';

const AutocompleteLoading: React.FC = () => (
  <div className="flex min-h-[84px] items-center justify-between gap-6 px-6">
    <div className="flex w-[30%] flex-none flex-col items-center gap-1">
      <Skeleton loading fontSize="fine-print" variant="text" />
      <Skeleton loading fontSize="fine-print" variant="text" />
    </div>
    <div className="flex w-[20%] flex-none items-center gap-1">
      <Skeleton loading fontSize="fine-print" variant="text" />
    </div>
    <div className="flex w-[28%] flex-none items-center gap-1">
      <Skeleton loading fontSize="fine-print" variant="text" />
    </div>
  </div>
);

export default AutocompleteLoading;
