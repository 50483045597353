import { useMemo } from 'react';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';

interface Props {
  /** The child elements to render if permission is granted */
  children: React.ReactNode;
  /**
   * The permission name to check. If not provided, the wrapper will always render its children
   * See apps/athena/hooks/use-permission.ts for the list of permission names
   * @example "users.create" | "posts.edit" | "admin.access"
   */
  name?: string | string[];
}

/**
 * A wrapper component that conditionally renders its children based on user permissions
 *
 * @example
 * <PermissionWrapper name={Permissions.commsEmailsAdmin}>
 *   <CreateUserButton />
 * </PermissionWrapper>
 */
const PermissionWrapper: React.ComponentType<Props> = ({ children, name }) => {
  const { currentCompanyProfileUser } = useCurrentCompanyProfileUser();

  const givenPermissionsHashSet = useMemo(() => {
    return new Set(
      currentCompanyProfileUser?.permissions.map((p) => p.name) ?? []
    );
  }, [currentCompanyProfileUser?.permissions]);

  const hasPermission = Array.isArray(name)
    ? name.every((p) => givenPermissionsHashSet.has(p))
    : name
    ? givenPermissionsHashSet.has(name)
    : true;

  const renderContent = () => {
    if (hasPermission) {
      return <>{children}</>;
    }

    return null;
  };

  return <>{renderContent()}</>;
};

export default PermissionWrapper;
