// DS V2
import { Tooltip, Typography } from '@ds';
import { InvestorSearchContactsAndShareholdingsQuery } from '@/apollo/generated';

interface Props {
  tags: NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<
          InvestorSearchContactsAndShareholdingsQuery['contacts']
        >['edges']
      >[0]
    >['node']
  >['tags'];
}

const Tags: React.FC<Props> = ({ tags }) => {
  const tagsShownLength = 3;

  const tagsString = tags
    .slice(3)
    .map((tag) => tag.name)
    .join(', ');

  return (
    <div className="flex flex-wrap items-center gap-1.5">
      {!tags.length ? '-' : null}
      {tags.slice(0, tagsShownLength).map((tag) => (
        <div
          key={tag.name}
          className="inline-flex h-[22px] items-center justify-center rounded-2xl bg-gray-100 px-2 py-0.5"
        >
          <Typography
            className="max-w-[160px] truncate whitespace-nowrap text-gray-700"
            variant="text-caption"
          >
            {tag.name}
          </Typography>
        </div>
      ))}
      {tags.length > tagsShownLength ? (
        <Tooltip description={tagsString} place="top-start">
          <div className="flex h-5 items-center justify-center rounded-2xl bg-gray-100 px-2 py-0.5">
            <Typography
              className="whitespace-nowrap text-gray-700"
              variant="text-caption"
            >
              {`+${tags.length - tagsShownLength}`}
            </Typography>
          </div>
        </Tooltip>
      ) : null}
    </div>
  );
};

export default Tags;
