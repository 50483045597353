import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { useOnClickOutside } from 'usehooks-ts';

interface Props {
  allowScrollable?: boolean;
  children?: React.ReactNode;
  className?: string;
  containerSize?: 'max-w-2xl' | 'max-w-md';
  onClose(): void;
  open: boolean;
}

const GenericModal: React.ComponentType<Props> = ({
  allowScrollable,
  children,
  className,
  containerSize = 'max-w-2xl',
  onClose,
  open,
}) => {
  const ref = useRef(null);
  useOnClickOutside(ref, onClose);

  return (
    <Transition.Root as={Fragment} show={open}>
      <Dialog
        className={clsx(
          'z-modal fixed inset-x-0 bottom-0 top-16 sm:top-0',
          !open && 'pointer-events-none'
        )}
        onClose={onClose}
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === 'Escape') {
            onClose();
          }
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-x-0 bottom-0 top-16 bg-black/30 sm:top-0" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="translate-y-full"
          enterTo="translate-y-0"
          leave="ease-in duration-200"
          leaveFrom="translate-y-0"
          leaveTo="translate-y-full"
        >
          <div
            className={clsx(
              `flex h-full flex-col items-center sm:p-6`,
              allowScrollable && 'overflow-y-auto'
            )}
          >
            <div className="sm:flex-1" />
            <div
              ref={ref}
              className={clsx(
                'relative w-full flex-1 overflow-y-auto bg-white sm:flex-initial sm:rounded-lg sm:shadow-lg',
                containerSize,
                className
              )}
            >
              {children}
            </div>
            <div className="sm:flex-1" />
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default GenericModal;
